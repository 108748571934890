import React from 'react'
const unidades = [
  {
    "CODIGO": "000000000",
    "NOME": "_TODOS ",
    "CODIGOGERENCIAL": "000",
    "PRINCIPAL": "S",
    "SIGLA": "TODOS ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "001000000",
    "NOME": "PRESIDENCIA DA FIOCRUZ ",
    "CODIGOGERENCIAL": "001",
    "PRINCIPAL": "S",
    "SIGLA": "PR ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "001010000",
    "NOME": "VICE-PRESID. DE PESQ. E COL. BIOLOGICAS ",
    "CODIGOGERENCIAL": "001",
    "PRINCIPAL": "S",
    "SIGLA": "VPPCB/PR ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "004000000",
    "NOME": "DIRETORIA DE PLANEJAMENTO ESTRATEGICO ",
    "CODIGOGERENCIAL": "004",
    "PRINCIPAL": "S",
    "SIGLA": "COGEPLAN ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "005000000",
    "NOME": "INSTITUTO OSWALDO CRUZ ",
    "CODIGOGERENCIAL": "005",
    "PRINCIPAL": "S",
    "SIGLA": "IOC ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "006000000",
    "NOME": "INSTITUTO FERNANDES FIGUEIRA ",
    "CODIGOGERENCIAL": "006",
    "PRINCIPAL": "S",
    "SIGLA": "IFF ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "007000000",
    "NOME": "ESCOLA NAC. SAÚDE PÚBLICA SERGIO AROUCA ",
    "CODIGOGERENCIAL": "007",
    "PRINCIPAL": "S",
    "SIGLA": "ENSP ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "008000000",
    "NOME": "COORDENAÇÃO-GERAL DE INFRAESTR DOS CAMPI",
    "CODIGOGERENCIAL": "008",
    "PRINCIPAL": "S",
    "SIGLA": "COGIC ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "009000000",
    "NOME": "INSTITUTO COMUNIC.INFO.CIENT.TECNOLOGICA",
    "CODIGOGERENCIAL": "009",
    "PRINCIPAL": "S",
    "SIGLA": "ICICT ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "010000000",
    "NOME": "INSTTITUTO DE TECNOLOGIA IMUNOBIOLOGICOS",
    "CODIGOGERENCIAL": "010",
    "PRINCIPAL": "S",
    "SIGLA": "BIO-MANGUINHOS ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "011000000",
    "NOME": "INSTITUTO DE TECNOL. EM FARMACOS ",
    "CODIGOGERENCIAL": "011",
    "PRINCIPAL": "S",
    "SIGLA": "FAR-MANGUINHOS ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "012000000",
    "NOME": "ESCOLA POLITECNICA DE SAUDE J.VENANCIO ",
    "CODIGOGERENCIAL": "012",
    "PRINCIPAL": "S",
    "SIGLA": "EPSJV ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "013000000",
    "NOME": "CENTRO DE EST. DA SAUDE DO TRAB.ECOL.HUM",
    "CODIGOGERENCIAL": "013",
    "PRINCIPAL": "S",
    "SIGLA": "CESTEH ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "014000000",
    "NOME": "CASA DE OSWALDO CRUZ ",
    "CODIGOGERENCIAL": "014",
    "PRINCIPAL": "S",
    "SIGLA": "COC ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "016000000",
    "NOME": "COORDENAÇÃO GERAL DE GESTÃO DE PESSOAS ",
    "CODIGOGERENCIAL": "016",
    "PRINCIPAL": "S",
    "SIGLA": "COGEPE ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "017000000",
    "NOME": "INST NAC DE INFECTOLOGIA EVANDRO CHAGAS ",
    "CODIGOGERENCIAL": "017",
    "PRINCIPAL": "S",
    "SIGLA": "INI ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "018000000",
    "NOME": "INSTITUTO CIENCIA E TECNOLOGIA BIOMODELO",
    "CODIGOGERENCIAL": "018",
    "PRINCIPAL": "S",
    "SIGLA": "ICTB ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "020000000",
    "NOME": "COORDENACAO GERAL DE ADMINISTRACAO ",
    "CODIGOGERENCIAL": "020",
    "PRINCIPAL": "S",
    "SIGLA": "COGEAD ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "026000000",
    "NOME": "APOSENTADOS RIO DE JANEIRO ",
    "CODIGOGERENCIAL": "026",
    "PRINCIPAL": "S",
    "SIGLA": "APOSENTADO-RJ ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "031000000",
    "NOME": "INSTITUTO RENNE RACHOU ",
    "CODIGOGERENCIAL": "031",
    "PRINCIPAL": "S",
    "SIGLA": "CPQRR ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "032000000",
    "NOME": "INSTITUTO AGGEU MAGALHAES ",
    "CODIGOGERENCIAL": "032",
    "PRINCIPAL": "S",
    "SIGLA": "CPQAM ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "033000000",
    "NOME": "INSTITUTO GONCALO MONIZ ",
    "CODIGOGERENCIAL": "033",
    "PRINCIPAL": "S",
    "SIGLA": "CPQGM ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "035000000",
    "NOME": "AUDITORIA INTERNA ",
    "CODIGOGERENCIAL": "035",
    "PRINCIPAL": "S",
    "SIGLA": "AUDIN/PR ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "036000000",
    "NOME": "PROCURADORIA FEDERAL ",
    "CODIGOGERENCIAL": "036",
    "PRINCIPAL": "S",
    "SIGLA": "PF ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "037000000",
    "NOME": "PESSOA JURIDICA ",
    "CODIGOGERENCIAL": "037",
    "PRINCIPAL": "S",
    "SIGLA": "PJ ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "038000000",
    "NOME": "PESSOA FISICA ",
    "CODIGOGERENCIAL": "038",
    "PRINCIPAL": "S",
    "SIGLA": "PF ",
    "SITUACAO": "A"
  },
  {
    "CODIGO": "039000000",
    "NOME": "ORGAO GOVERNAMENTAL ",
    "CODIGOGERENCIAL": "039",
    "PRINCIPAL": "S",
    "SIGLA": "OG ",
    "SITUACAO": "A"
  },
  { "CODIGO": "042000000" }
]

const PageTest = () => { return unidades.map((unidade)=>(<div> <p>{unidade.SIGLA} - {unidade.NOME}</p></div>))}


export default PageTest;