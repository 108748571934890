import React from 'react';
import BoxHeader from "./BoxHeader";
import {Link} from "react-router-dom";




const MapLegado = (props) => (

  <div className="map-legado">
    <div className="background-legado"></div>
    <div className="map-legado-grid">
      <BoxHeader title={props.data.title}/>
      {props.data.textRow_1.map((item, index) => (
        <Link to="/legado" key={`photo-${index}`} className={`box-legado row1item${index} ms-shadow`}>
          <p>{item}</p>
        </Link>
      ))}
      {props.data.textRow_2.map((item, index) => (
        <Link to="/legado" key={`photo-${index}`} className={`box-legado row1item${index} ms-shadow`}>
          <p>{item}</p>
        </Link>
      ))}
    </div>
  </div>

)


export default MapLegado