import React from 'react';
import BoxHeader from "./BoxHeader";
import {Link} from "react-router-dom";


const MapResultados = (props) => (

  <div className="map-resultado">
    <div className="map-resultado-grid">
      <BoxHeader title={props.data.title}/>
      <Link to="/resultado-geral" className="box-bar-sub ms-shadow">
        <h5>{props.data.barSubtitle}</h5>
      </Link>
      {props.data.textRow_2.map((item, index) => (
        <Link to={`resultados/${item.slug}`} key={`resultado-${index}`} className={`box-resultado row2item${index} ms-shadow`}>
          <p>{item.text}</p>
        </Link>
      ))}
    </div>
  </div>
)


export default MapResultados