import React from 'react'
import pdf from  "../file/VIII-congresso-interno_relatorio-final.pdf"

const MapHeader = (props) => (
  <div className="map-header">
    <div className="missao ms-shadow">
      <a href={pdf} target="_blank">
        <h5>Missão</h5>
        <p>{props.data.missao}</p>
      </a>
    </div>
    <div className="visao ms-shadow">
      <a href={pdf} target="_blank">
        <h5>Visão</h5>
        <p>{props.data.visao}</p>
      </a>
    </div>
  </div>
)

export default MapHeader