import React, {useState, useEffect }from 'react';
import {Link} from "react-router-dom";
import contentProjetos from "../payload/projetos";


const link = (type) =>{
  if(type === "processo"){
    return '/processos/projetos/'
  }else if(type === "compromisso"){
    return '/projetos/'
  }
}

const normalizar = (STRING) => {
  let value = STRING.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
  return value.replace(/\s+/g, '-')
}

const BoxProjetos = (props) => {
  const projectName = normalizar(props.title);
  const seen = new Set();

  const filterData = (data) => {
    const filterData = data.filter(el =>  {
      if(el[props.type]){
        let projectType = normalizar(el[props.type]);
        return !projectType.localeCompare(projectName);
      }
    });

    return filterData.filter(el => {
      if('cid' in el){
        const duplicate = seen.has(el.cid);
        seen.add(el.cid);
        return !duplicate;
      }
      return;
    });
  }

  const dataProjetos = filterData(contentProjetos)

  // const [dataProjetos, setDataProjetos] = useState();

  // useEffect(()=>{
  //   if(!dataProjetos){
  //     fetch('http://localhost:5000/projects').then((response) => {
  //       if(response.ok) {
  //         console.log('fetch to api');
  //         response.json().then(data => setDataProjetos(filterData(data)));
  //       } else {
  //         console.log('Network response was not ok.');
  //         setDataProjetos(contentProjetos);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('There has been a problem with your fetch operation: ');
  //       // setDataProjetos(contentProjetos);
  //     });
  //   }
  // },[dataProjetos])

  console.log('tem projetos ? ', dataProjetos);
  if(!dataProjetos || dataProjetos.length === 0 ){
    return null;
  }

  return(
    <div className="box-projetos">
      <h3>Projetos</h3>
      <ol>
      {dataProjetos.map((projeto, index)=>{
        return (
          <li key={`projeto_${index}`}>
            <Link className="projeto" to={`${link(props.type)}${projeto.cid}`} >
              {projeto.titulo}
            </Link>
          </li>
        )
      })}
      </ol>
    </div>
)}

export default BoxProjetos