import React from "react";
import { useParams, Link } from "react-router-dom";
import content from "../payload/contentGestaoProcessos";
import PainelIndicadores from "../components/PainelIndicadores";

const PageGestaoProcessos = (props) => {
  let { slug } = useParams();
  const title = content[slug].title
  const indicadores = content[slug].indicadores;
  const multipleIndicador = content[slug].multipleIndicador;

  const printText = () => {
    const texts = content[slug].text;
    if (texts.includes("\n")) {
      const arrText = texts.split("\n");
      return arrText.map((text, index) => (
        <p key={index}>
          {text}
          <br />
        </p>
      ));
    }
    return (
      <p>
        {content[slug].text}
        <br />
      </p>
    );
  };

  return (
    <div className="page-gestao-processos page">
      <div className="page-title">
        <h2>{title}</h2>
      </div>
      <div className="page-text">
        {printText()}
        <br />
      </div>
      {indicadores ? (
        <PainelIndicadores
          indicadores={indicadores}
          multipleIndicador={multipleIndicador}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default PageGestaoProcessos;
