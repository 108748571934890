import React from 'react';
import Routers from './Routers';

const Template = () => (
  <div className=""> 
    <header className="header">
      <div className="header-com-torres">
        <div className="container">
        <a href="https://portal.fiocruz.br/" title="Fundação Oswaldo Cruz uma instituição a serviço da vida" className="active">
          <div className="logo-fiocruz"></div>
        </a>
        <a href="/" className="back-map">
          Mapa Estratégico
        </a>
        </div>
      </div>
    </header>
    <div className="container-com-torres">
      <main className="container"> 
        <Routers />
      </main>
    </div>
  </div>
)


export default Template