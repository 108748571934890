import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import contentProjetos from "../payload/projetos";


const PageProjetos = (props) => {
  let { id } = useParams();
  const seen = new Set();

  const filterData = (data) => {
    const filterData = data.filter(el =>  el.cid === id);

    return filterData.map(el => {
      const duplicate = seen.has(el.cid);
      seen.add(el.cid);
      if(duplicate){
        return {
          cid: el.cid,
          produto: el.produto,
          qtdPrev: el.qtdPrev,
          qtdExc: el.qtdExc,
        }
      } else {
        return el
      }
    });
  }

  const dataProjetos = filterData(contentProjetos);

  // const [dataProjetos, setDataProjetos] = useState();

  useEffect(()=>{
    // if(!dataProjetos){
      fetch('https://ei.fiocruz.br/services/MapaCovid19/RelatorioGeral')
      .then(response => response.text())
      .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
      .then(data => console.log('data fetch api', data))
      // .then((response) => {
      //   console.log(response);

      //   // if(response.ok) {
      //   //   console.log('fetch to api');
      //   //   response.json().then(data => setDataProjetos(filterData(data)));
      //   // } else {
      //   //   console.log('Network response was not ok.');
      //   //   setDataProjetos(contentProjetos);
      //   // }
      // })
      .catch((error) => {
        console.log('Error no fetch api');
        // setDataProjetos(contentProjetos);
      });
    // }
  },[])

  return (
    <div className="page-compromissos page">
      {dataProjetos 
        ? dataProjetos.map((projeto, index)=>(
          <div key={index}>
            <div className="page-title">
              <h2>{projeto.compromisso}</h2>
            </div>
            <table className="tables-indicador  table-full">
              <tbody>
                {projeto.codigo && <tr ><th>Código do SAGE: </th><td>{projeto.codigo}</td></tr>}
                {projeto.titulo && <tr><th>Título: </th><td>{projeto.titulo}</td></tr>}
                {projeto.descricao && <tr><th>Descrição: </th><td>{projeto.descricao}</td></tr>}
                {projeto.unidade && <tr><th>Unidade(s) responsável(is): </th><td>{projeto.unidade}</td></tr>}
                <tr><th>Produto: </th><td>{projeto.produto}</td></tr>
                <tr><th>Quantidade Prevista: </th><td>{projeto.qtdPrev}</td></tr>
                <tr><th>Quantidade Executada: </th><td>{projeto.qtdExe}</td></tr>
              </tbody>
            </table>
          </div>
        ))
        : <h1>Loading...</h1>
      }
    </div>
  );
};

export default PageProjetos;
