import React from 'react';
import BoxHeader from "./BoxHeader";
import {Link} from "react-router-dom";



const MapProcessos = (props) => (

  <div className="map-processos">
    <div className="background-processo"></div>
    <div className="map-processo-grid">
      <BoxHeader title={props.data.title}/>
      {props.data.textRow_1.map((item, index) => (
        <Link to={`/gestao-processos/${item.slug}`} key={`gestao-processo-${index}`} className={`box-processo row1item${index} ms-shadow`}>
          <h5>{item.text}</h5>
        </Link>
      ))}
      {props.data.textRow_2.map((item, index) => (
        <Link to={`/processos/${item.slug}`} key={`one-processo-${index}`} className={`box-processo row2item${index} ms-shadow`}>
          <p>{item.text}</p>
        </Link>
      ))}
      {props.data.textRow_3.map((item, index) => (
        <Link to={`/processos/${item.slug}`} key={`two-processo-${index}`} className={`box-processo row3item${index} ms-shadow`}>
          <p>{item.text}</p>
        </Link>
      ))}
    </div>
  </div>

)

export default MapProcessos