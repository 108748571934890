import React from "react";
import { useParams } from "react-router-dom";
import contentProjetos from "../payload/projetos";


const PageProjetosProcessos = (props) => {
  let { id } = useParams();


  return (
    <div className="page-compromissos page">
      {
        contentProjetos.map((projeto, index)=>{
          if(projeto.cid === id){
            return(
              <div key={index}>
                <div className="page-title">
                  <h2>{projeto.processo}</h2>
                </div>
                <table className="tables-indicador  table-full">
                  <tbody>
                    <tr >
                      <th>Código do SAGE: </th>
                      <td>{projeto.codigo}</td>
                    </tr>
                    <tr>
                      <th>Título: </th>
                      <td>{projeto.titulo}</td>
                    </tr>
                    < tr >
                      <th>Descrição: </th>
                      <td>{projeto.descricao}</td>
                    </tr>
                    <tr>
                      <th>Unidade(s) responsável(is): </th>
                      <td>{projeto.unidade}</td>
                    </tr>
                    <tr>
                      <th>produto: </th>
                      <td>{projeto.produto}</td>
                    </tr>
                    <tr>
                      <th>Quantidade Prevista: </th>
                      <td>{projeto.qtdPrev}</td>
                    </tr>
                    <tr>
                      <th>Quantidade Executada: </th>
                      <td>{projeto.qtdExe}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
        })
      }
    </div>
  );
};

export default PageProjetosProcessos;
