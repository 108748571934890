import React from 'react';
import BoxHeader from "./BoxHeader";
import {Link} from "react-router-dom";



const MapCompromissos = (props) => (
  <div className="map-compromissos">
    <div className="map-compromisso-grid">
      <BoxHeader title={props.data.title}/>
      {props.data.content.map((item, index) => {
        if(item.slug){
          return (
          <Link to={`/compromissos/${item.slug}`} key={`compromisso-${index}`} className={`box-compromisso ${item.attr} ms-shadow`}>
            <p>{item.text}</p>
          </Link>
          )
        }else{
          return (
          <div to={`/compromissos/${item.slug}`} key={`compromisso-${index}`} className={`box-compromisso ${item.attr} ms-shadow`}>
            <p>{item.text}</p>
          </div>
          )
        }
      })}
    </div>
  </div>
)

export default MapCompromissos