import React from 'react'

const PageLegado = () => (
  <div className="page-legado page"> 
    <div className="page-title"> 
      <h2>Legado Institucional</h2>
    </div>
    <div>
      <p>A atuação intensiva da Fiocruz no enfrentamento da pandemia pelo novo coronavírus deixará, com certeza, legados de importância para o crescimento institucional e para o fortalecimento da Fiocruz como órgão de Estado com o protagonismo e competência suficiente como preparação para situações similares futuras. Diversos estudos apontam a possibilidade da emergência de outras grandes ondas epidêmicas e pandemias. Dessa forma, o desenvolvimento de capacidade de resposta rápida, eficaz e com resultados duradouros é uma das características da Fiocruz, que se expressam como o Legado Institucional da pandemia.</p>
      <br/>
      <p>À medida que a situação de emergência se desenrola, há a necessidade de revisão do plano e correção dos rumos estratégicos, assim como, podem surgir novos legados das iniciativas da Fiocruz para o enfrentamento da pandemia. Destacam-se a seguir, os legados possíveis já identificados:</p>
      <ul>
        <li>Estrutura para o fortalecimento da pesquisa clínica no Brasil</li>
        <li>Processo de formação ágil de profissionais por meio de EAD</li>
        <li>Elaboração de central analítica de alta capacidade de processamento</li>
        <li>Maior autonomia nacional na produção de insumos estratégicos</li>
        <li>Plataformas tecnológicas para os laboratórios de referência</li>
        <li>Fortalecimento do sistema de vigilância</li>
        <li>Reorganização das estruturas assistenciais da Fiocruz</li>
        <li>Melhoria dos processos para possibilitar o trabalho remoto nas várias áreas de ação</li>
        <li>Acúmulo de experiências no enfrentamento de pandemias.</li>
        <li>Preservação da memória institucional das ações e iniciativas no enfrentamento de pandemias.</li>
        <li>Melhoria nos processos comunicacionais em situações de crise sanitária.</li>
        <li>Fortalecimento do sistema de vigilância</li>
      </ul>

    </div>
  </div>
)


export default PageLegado;