import React from 'react'

const PageMetodologia = () => (
  <div className="page-metodologia page"> 
    <div className="page-title"> 
      <h2>Metodologia do Mapa Estratégico</h2>
    </div>
    <div>
    <p>
      O planejamento estratégico pode ser visto como uma tentativa de mudança de curso de determinados acontecimentos, contidos em um sistema complexo. Essa tentativa, expressa como estratégia, busca dar direcionalidade e dessa forma, alterar um futuro antevisto. O presente Plano nada mais é que a tentativa da Fiocruz em organizar estrategicamente e comunicar de forma clara e concisa, os esforços institucionais, para o enfrentamento da pandemia por COVID-19.
    </p><br/>
    <p>
      Em 2018, a Fiocruz publicou o Relatório Final do VIII Congresso Interno, que define suas principais diretrizes estratégicas bem como reafirma seus compromissos com a sociedade, na declaração de sua missão, visão e valores institucionais. Dessa forma, o presente Plano foi organizado com base em premissas institucionais e voltado para a articulação de ações para o enfrentamento da pandemia, estruturado em nove seções.
    </p><br/>
    <p>
      A primeira seção apresenta o problema em seu Contexto Atual. Na segunda e terceira seções, estão descritos os motivadores do plano na forma de Justificativa e Objetivos. A Metodologia é apresentada na quarta seção. A quinta seção representa o núcleo do Plano e descreve o Legado, os Compromissos e Resultados para a Sociedade, enquanto os Processos de Suporte são apresentados na seção seis. Na sétima e última seção está a proposta de Gestão do Plano, com o painel de indicadores para o monitoramento e avaliação das iniciativas, seus esforços e resultados. 
    </p><br/>
    <p>
      Foi utilizado como pressuposto metodológico para a elaboração do plano, a sua demonstração no formato de mapa estratégico, em especial por sua característica de apresentar de forma clara como as iniciativas podem estar alinhadas aos objetivos definidos pela instituição. Além disso, mapas estratégicos favorecem a coordenação de ações estratégicas e colaborativas.
    </p><br/>
    <p>
      Para isso, foram identificados os principais objetivos estratégicos advindos das iniciativas que contribuem para o alcance de resultados significativos para a sociedade, e categorizados dentro das principais linhas de atuação da Fiocruz. Da mesma forma, foram identificados e relacionados os objetivos que se relacionam aos processos de suporte e às iniciativas a serem desenvolvidas. Finalmente, ainda na lógica do mapa estratégico, foram construídos os indicadores que possibilitarão a gestão do plano.
    </p><br/>
    <p>
      O plano estratégico requer o desdobramento dos objetivos em iniciativas concretas e a gestão dessas ações em ambiente tático e operacional de múltiplas restrições que necessitam ser superadas. A organização das atividades da Fiocruz está orientada a partir dos resultados que podem ser gerados para a sociedade, sendo o principal, a redução dos impactos causados pela COVID-19. A partir dos resultados são desenvolvidas iniciativas alinhadas à missão e visão da Fiocruz, de forma a fortalecer o Sistema Único de Saúde e melhorar a saúde das pessoas. As iniciativas se desdobram em projetos e operações nas inúmeras unidades técnico-científicas da Fiocruz, gerando produtos e resultados.
    </p><br/>
    <p>
      O mapa estratégico foi estruturado em quatro dimensões que se interligam por iniciativas e/ou objetivos. A Missão e Visão da Fiocruz, ao serem apresentadas nesse mapa reforçam seu compromisso com a sociedade em defesa da vida e saúde e seus valores. A dimensão dos Processos de Suporte evidencia quais são as principais iniciativas e processos que, operando de forma integrada e coordenada, garantam a realização das iniciativas que compõem os Compromissos com a Sociedade, que representam a dimensão executiva do plano. Aqui, se detalham os objetivos a serem perseguidos para que se alcancem os Resultados para a Sociedade, declarados como as metas mais relevantes a serem atingidas.
    </p><br/>
    <p>
      As iniciativas - operações e projetos - ligadas às áreas finalísticas da Fiocruz que estejam direta ou indiretamente envolvidas com o enfrentamento da pandemia, se constituem nas estratégias institucionais para que a Redução dos impactos causados pela emergência de saúde pública de importância internacional decorrente do coronavírus possa ser construída em um horizonte de futuro próximo, detalhadas em metas tangíveis e mensuráveis
    </p><br/>
    <p>
      A última dimensão do mapa é a dimensão do Legado, dos resultados mais perenes dos Compromissos assumidos pela Fundação. O Legado se constitui fortemente na capacidade da instituição de estar preparada para responder a desafios futuros, a partir de lições aprendidas, da ampliação das suas competências institucionais e individuais da ampliação e qualificação de estruturas, e principalmente da resiliência, característica fundamental da Fiocruz.
    </p><br/>
    </div>
  </div>
)


export default PageMetodologia;