import React from 'react'
import { Link } from 'react-router-dom'
import content from '../payload/contentCompromissos'
import PainelIndicadores from '../components/PainelIndicadores'


const printIndicadores = () => {

  return Object.keys(content).map((key, index) => {
    if(content[key].indicadores){

      return (
      <div class="indicador">
        <Link class="title-page-link" to={`/compromissos/${key}`} >{content[key].title}</Link>
        <PainelIndicadores
          indicadores={content[key].indicadores}
          multipleIndicador={content[key].multipleIndicador}
        />
      </div>
      )
    }
  });
}


const PageResultadoGeral = () => {

  return (
    <div className="page-resultado page"> 
      <div className="page-title"> 
        <h2>Redução dos impactos causados pela emergência de saúde pública de importância internacional decorrente do coronavírus </h2>
      </div>
      <div className="page-text">
        <p>O enfrentamento da emergência de saúde pública de importância internacional decorrente do coronavírus na Fiocruz tem como principal objetivo reduzir os impactos negativos na morbimortalidade da população brasileira.</p>
      </div>
      <div className="Indicador">
          <h3>Painel de Indicadores para acompanhamento dos compromissos para a sociedade </h3>
          {printIndicadores()}
      </div>
    </div>
  )
}


export default PageResultadoGeral;