import React from "react";

const PainelIndicadores = (props) => {
  const indicadores = props.indicadores;
  const multipleIndicador = props.multipleIndicador;

  const printIndicadores = () => {
    if (multipleIndicador) {
      return indicadores.map((indicadorlTable, index) => (
        <table key={`table_${index}`} className="tables-indicador">
          <tbody>
            {indicadorlTable.map((indicador, index) => (
              <tr key={`row_${index}`}>
                <th>{indicador.title}</th>
                <td>{indicador.text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ));
    } else {
      return (
        <table className="tables-indicador">
          <tbody>
            {indicadores.map((indicador, index) => (
              <tr key={`row_${index}`}>
                <th>{indicador.title}</th>
                <td>{indicador.text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  };

  return <div className="Indicador">{printIndicadores()}</div>;
};

export default PainelIndicadores;
