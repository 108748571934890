import React from 'react';
import {Link} from "react-router-dom";


const BoxHeader = (props) => (
  <Link to="metodologia-mapa-estrategico" className="box-header  ms-shadow">
    <div className="">
        <h5 className="rotate">{props.title}</h5>
    </div>
  </Link>
)


export default BoxHeader