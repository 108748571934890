import React from 'react'
import MapHeader from '../components/MapHeader'
import mapData from '../payload/map'
import MapLegado from '../components/MapLegado'
import MapResultados from '../components/MapResultados'
import MapCompromissos from '../components/MapCompromissos'
import MapProcessos from '../components/MapProcessos'



const PageMap = () => (
  <div className="page-map page"> 
    <div className="page-title"> 
      <h2>Mapa Estratégico da Fiocruz para enfretamento da emergência de saúde pública de importância internacional decorrente do coronavírus</h2>
    </div>
    <MapHeader data={mapData.header} />
    <MapLegado data={mapData.legado} />
    <div className="background-center-map">
      <div className="back-box"></div>
      <div className="back-box"></div>
      <div className="back-box"></div>
      <div className="back-box"></div>
      <div className="back-box"></div>
      <div className="back-box"></div>
    </div>
    <MapResultados data={mapData.resultado} />
    <MapCompromissos data={mapData.compromisso} />
    <MapProcessos data={mapData.processo} />
  </div>
)


export default PageMap;