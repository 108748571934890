import React, { Component } from "react";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import XLSX from "xlsx";
import * as FileSaver from 'file-saver';
import api from "../services/api";
import { Form, Container } from "../components/Login/Login.style";



class PageUploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      selectedFile: null,
      error: "",
      success: "",
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    // const files = e.target.files;
    // if (files && files[0]) this.setState({ file: files[0] });
    this.setState({
      selectedFile: e.target.files[0],
      loaded: 0,
    })
  }

  handleFile = async e => {
    e.preventDefault();
    const data = new FormData() 
    data.append('file', this.state.selectedFile)
    // console.log(this.state.selectedFile);
    try {
      const response = await api.post("/api/uploadfile", this.state.selectedFile)
      console.log('response', response);
      // this.props.history.push("/upload-projetos")
      this.setState({
        success: response.data.msg
      })
    } catch (err) {
      console.log('error',err)
      this.setState({
        error:
          "Houve um problema com o upload do arquivo"
      })
    }
  
  }

  render() {
    
    return (
      <Container>
        <Form onSubmit={this.handleFile} >
          {this.state.error && <p>{this.state.error}</p>}
          {this.state.success && <p className="success-msg">{this.state.success}</p>}
          <label htmlFor="file">upload de projetos por planilha xlsx</label>
          <br />
          <input
            type="file"
            className="form-control"
            id="file"
            onChange={this.handleChange}
          />
          <br />
          <button type="submit">Enviar</button>
        </Form>
      </Container>
    );
  }
}

export default PageUploadFile;
