import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PageMap from './pages/PageMap'
import PageLegado from './pages/PageLegado'
import PageMetodologia from './pages/PageMetodologia'
import PageResultados from './pages/PageResultados'
import PageResultadoGeral from './pages/PageResultadoGeral'
import PageCompromissos from './pages/PageCompromissos'
import PageProjetos from './pages/PageProjetos'
import PageProcessos from './pages/PageProcessos'
import PageGestaoProcessos from './pages/PageGestaoProcessos'
import PageProjetosProcessos from './pages/PageProjetosProcessos'
import PageTest from './pages/PageTest'
import PageLogin from './pages/PageLogin'
import PageUploadFile from './pages/PageUploadFile'

import { isAuthenticated } from "./services/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)


const Routers = () => (
  <Router>
      <Switch>
        <Route path="/test" children={<PageTest />}/>
        <Route path="/login" children={<PageLogin />}/>
        <PrivateRoute path="/upload-projetos" children={<PageUploadFile />} />
        <Route path="/gestao-processos/:slug" children={<PageGestaoProcessos />}/>
        <Route path="/processos/projetos/:id" children={<PageProjetosProcessos />}/>
        <Route path="/processos/:slug" children={<PageProcessos />}/>
        <Route path="/projetos/:id" children={<PageProjetos />}/>
        <Route path="/compromissos/:slug" children={<PageCompromissos />}/>
        <Route path="/resultado-geral" children={<PageResultadoGeral />}/>
        <Route path="/resultados/:slug" children={<PageResultados />}/>
        <Route path="/metodologia-mapa-estrategico" children={<PageMetodologia />}/>
        <Route path="/legado" children={<PageLegado />}/>
        <Route path="/" children={<PageMap/>}/>
      </Switch>
  </Router>
)


export default Routers