import React from 'react'
import {useParams} from "react-router-dom"
import content from '../payload/contentResultados'
import { Link } from 'react-router-dom'
import compromissos from '../payload/contentCompromissos'
import PainelIndicadores from '../components/PainelIndicadores'



const PageResultados = (props) => {

  let { slug } = useParams()

  const printIndicadores = () => {

    return content[slug].indicadores.map((key, index) => {
      if(compromissos[key].indicadores){

        return (
        <div class="indicador">
          <Link class="title-page-link" to={`/compromissos/${key}`} >{compromissos[key].title}</Link>
          <PainelIndicadores
            indicadores={compromissos[key].indicadores}
            multipleIndicador={compromissos[key].multipleIndicador}
          />
        </div>
        )
      }
    });
  }

  return (
    <div className="page-resultado page"> 
      <div className="page-title"> 
        <h2>{content[slug].title}</h2>
      </div>
      <div className="page-text">
        {content[slug].text}
      </div>
      <div className="Indicador">
        <h2>Painel de Indicadores para acompanhamento dos compromissos para a sociedade relacionados a esse resultado </h2>
      
        {printIndicadores()}
      </div>
    </div>
  )
}


export default PageResultados;